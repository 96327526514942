<template>
  <v-container
    id="login"
    class="fill-height justify-center pa-0"
    tag="section"
    style="background: #122338; width: 450px"
  >
    <v-card dark width="450" class="px-5 my-4" color="#122338">
      <v-alert v-if="alert.status" type="error" dismissible class="mb-3 mt-0">
        {{ alert.message }}
      </v-alert>

      <v-row>
        <v-row justify="center">
          <v-img center max-width="174" src="@/assets/logo.svg" class="mt-8" />
          <v-card-text class="text-center mb-4">
            <strong>Login</strong> to your Account
          </v-card-text>
        </v-row>
      </v-row>

      <v-row v-if="loading" class="fill-height" justify="center">
        <v-progress-circular :size="50" color="primary" indeterminate />
      </v-row>

      <v-form
        v-else
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="login"
      >
        <v-card-text class="text-center pb-2">
          <v-text-field
            v-model="credentials.username"
            name="username"
            color="secondary"
            label="Email..."
            prepend-inner-icon="mdi-email"
            required
            :counter="70"
            maxlength="70"
            :rules="rules.username"
          />

          <v-text-field
            v-model="credentials.password"
            name="password"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            class="mb-8"
            color="secondary"
            label="Password..."
            prepend-inner-icon="mdi-lock-outline"
            required
            :counter="20"
            maxlength="20"
            :rules="rules.password"
            :type="passwordShow ? 'text' : 'password'"
            @click:append="passwordShow = !passwordShow"
          />

          <v-btn
            id="login-button"
            type="submit"
            color="#F49700"
            depressed
            :disabled="!valid"
          >
            Login
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios"
import router from "../../router"
import store from "@/store"
import common from "@/utils/common"

export default {
  name: "PagesLogin",

  data: () => ({
    credentials: {},
    passwordShow: false,
    alert: {
      status: false,
      message: null,
    },
    valid: true,
    loading: false,
    rules: {
      username: [
        (v) => !!v || "Username is required",
        (v) =>
          (v && v.length > 3) ||
          "A username must be more than 3 characters long.",
        (v) =>
          /^[a-zA-Z0-9@._]+$/.test(v) ||
          "A username can only contain letters and digits",
      ],
      password: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length > 7) ||
          "The password must be longer than 7 characters",
      ],
    },
  }),
  methods: {
    async login() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.loading = true

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/api/token/`,
          this.credentials
        )

        this.$cookie.set("token", response.data.access, {
          expires: "24h",
        })

        const data = await store.dispatch("generic/GET_AGENT_DETAIL")

        if (data.user.isAgent) {
          common.methods.clearAllLocalStorage()
          this.loadInitialData()

          if (this.$route.query.redirect) {
            router.push(this.$route.query.redirect)
          } else {
            router.replace({ name: "Loads" })
          }
        } else {
          this.loading = false
          this.alert.status = true
          this.alert.message = "This login not related to an agent"
          this.$cookie.delete("token")
          this.$cookie.delete("django_redirect_url")
        }
      } catch (e) {
        this.loading = false
        this.alert.status = true
        this.alert.message = e.response?.data?.detail ?? "An unexpected error has occured."
      }
    },

    loadInitialData() {
      this.$store.dispatch("generic/GET_EQUIPMENT_TYPE")
      this.$store.dispatch("generic/GET_COUNTRIES")
      this.$store.dispatch("generic/GET_ORDER_TYPES")
      this.$store.dispatch("generic/GET_MODES_CHOICES")
      this.$store.dispatch("loadDetail/GET_LOAD_STATUS_CHOICES")
      this.$store.dispatch("loadDetail/GET_LOADBOARDS")
      this.$store.dispatch("carrier/GET_CARRIER_INSURANCE_TYPES")
    },
  },
}
</script>
